import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import './Button.css';

const Button = ({ children, href, rel, size, target, to }) => {
  const mainClassName = `Button`;

  const rootClassName = classnames(
    mainClassName,
    `${mainClassName}--size-${size}`
  );

  if (href) {
    return (
      <a className={rootClassName} href={href} rel={rel} target={target}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link className={rootClassName} rel={rel} target={target} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <button className={rootClassName} disabled>
      {children}
    </button>
  );
};

export default Button;
