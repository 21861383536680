import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import ImageProxy from '../../01-atoms/ImageProxy/ImageProxy';

import logo from '../../../images/Sequencing.com-Logo--Outsmart-Your-Genes.svg';

import './Modal.css';

if (typeof window !== `undefined`) {
  const selector = `#___gatsby`;
  const appElement = document.querySelector(selector);
  if (appElement) {
    ReactModal.setAppElement(selector);
  }
}

const Modal = ({
  children,
  closeModal,
  isOpen,
  className,
  overlayClassName,
}) => (
  <ReactModal
    className={classnames(`Modal`, className)}
    isOpen={isOpen}
    onRequestClose={closeModal}
    overlayClassName={overlayClassName}
  >
    <div className="Modal__heading">
      <ImageProxy alt="Sequencing.com" src={logo} />
      <button className="Modal__close-button" onClick={closeModal}>
        Close dialog
      </button>
    </div>
    {children}
  </ReactModal>
);

export default Modal;
