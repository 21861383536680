import React from 'react';

import Button from '../../01-atoms/Button/Button';

import './SimpleCtaSection.css';

const SimpleCtaSection = ({ children, ctaUrl, ctaLabel }) => {
  console.log(`render SimpleCtaSection`);

  return (
    <div className="SimpleCtaSection">
      {!!children && <div className="SimpleCtaSection__body">{children}</div>}

      {!!ctaLabel && (
        <div className="SimpleCtaSection__footer">
          <Button href="ctaUrl">{ctaLabel}</Button>
        </div>
      )}
    </div>
  );
};

export default SimpleCtaSection;
