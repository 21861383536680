import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Modal from '../../03-organisms/Modal/Modal';

import './TestProvidersList.css';

const providers = [
  { id: `23andMe`, label: `23andMe`, href: `/hello/23andme` },
  { id: `ancestryDna`, label: `AncestryDNA`, href: `/hello/ancestrydna` },
  { id: `familyTreeDna`, label: `FamilyTreeDNA`, href: `/hello/familytreedna` },
  { id: `myHeritageDna`, label: `MyHeritage DNA`, href: `/hello/myheritage` },
  {
    id: `danteLabs`,
    label: `Dante Labs`,
    href: `/hello/dante-labs-genome-data-import`,
  },
  {
    id: `nebulaGenomics`,
    label: `Nebula Genomics`,
    href: `/hello/nebula-dna-data-import`,
  },
  { id: `dnaLand`, label: `DNA Land`, href: `/hello/dnaland` },
  { id: `homeDna`, label: `HomeDNA`, href: `/hello/homedna` },
  { id: `livingDna`, label: `Living DNA`, href: `/hello/livingdna` },
  { id: `vitagene`, label: `Vitagene`, href: `/hello/vitagene` },
  { id: `tellmeGen`, label: `tellmeGen` },
  { id: `evogenom`, label: `Evogenom` },
];

const PureTestProvidersList = ({
  className,
  id,
  providerImages,
  kits,
  kitsSettings,
  plans,
  semanticLevel = 1,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const closeModal = () => {
    if (!isModalOpen) {
      return;
    }
    setIsModalOpen(false);
  };

  const openModal = () => {
    if (isModalOpen) {
      return;
    }
    setIsModalOpen(true);
  };

  return (
    <div className="TestProvidersList">
      <Modal closeModal={closeModal} isOpen={isModalOpen}>
        <div className="TestProvidersList__modal-content">
          Please obtain your DNA data from this provider and upload the file
          using our&nbsp;<a href="/data/upload">Upload&nbsp;Center</a>.
        </div>
      </Modal>
      <ul className="TestProvidersList__provider-list">
        {providers.map((provider) => (
          <li className="TestProvidersList__provider-item" key={provider.id}>
            {!provider.href && (
              <button onClick={openModal}>
                <GatsbyImage
                  alt={provider.label}
                  className="TestProvidersList__provider-image"
                  image={providerImages[provider.id]}
                />
              </button>
            )}
            {!!provider.href && (
              <a href={provider.href}>
                <GatsbyImage
                  alt={provider.label}
                  className="TestProvidersList__provider-image"
                  image={providerImages[provider.id]}
                />
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const withProviderImages = (Component) => (props) => (
  <StaticQuery
    query={graphql`
      {
        img_23andMe: file(
          relativePath: { eq: "dna-upload-providers/23andme.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_ancestryDna: file(
          relativePath: { eq: "dna-upload-providers/ancestry-dna.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_danteLabs: file(
          relativePath: { eq: "dna-upload-providers/dante-labs.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_dnaLand: file(
          relativePath: { eq: "dna-upload-providers/dna-land.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_evogenom: file(
          relativePath: { eq: "dna-upload-providers/evogenom.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_familyTreeDna: file(
          relativePath: { eq: "dna-upload-providers/family-tree-dna.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_homeDna: file(
          relativePath: { eq: "dna-upload-providers/homedna.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_livingDna: file(
          relativePath: { eq: "dna-upload-providers/living-dna.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_myHeritageDna: file(
          relativePath: { eq: "dna-upload-providers/myheritagedna.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_nebulaGenomics: file(
          relativePath: { eq: "dna-upload-providers/nebula-genomics.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_tellmeGen: file(
          relativePath: { eq: "dna-upload-providers/tellmegen.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
        img_vitagene: file(
          relativePath: { eq: "dna-upload-providers/vitagene.logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 220, quality: 80, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <Component
        {...props}
        providerImages={Object.keys(data).reduce((acc, key) => {
          acc[key.replace(`img_`, ``)] =
            data[key].childImageSharp.gatsbyImageData;
          return acc;
        }, {})}
      />
    )}
  />
);

export { PureTestProvidersList, withProviderImages };
export default withProviderImages(PureTestProvidersList);
