import React from 'react';
import { Link } from 'gatsby';

import './TableOfContents.css';

const renderTableOfContentsItem = (item) => (
  <li>
    <Link to={item.url}>{item.title}</Link>
    {!!item.items?.length && renderTableOfContentsList(item.items)}
  </li>
);

const renderTableOfContentsList = (list) => (
  <ol>{list.map((item) => renderTableOfContentsItem(item))}</ol>
);

const TableOfContents = ({ tableOfContents }) => (
  <div className="TableOfContents">
    {renderTableOfContentsList(tableOfContents.items)}
  </div>
);

export default TableOfContents;
