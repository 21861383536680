import React from 'react';

import Button from '../../01-atoms/Button/Button';

const ContentButton = ({ children, href, size, target }) => {
  const props = {
    href,
    size,
  };

  if (target === `_blank`) {
    props.target = target;
    props.rel = `noopener`;
  }

  return <Button {...props}>{children}</Button>;
};

export default ContentButton;
