import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageProxy = ({
  alt,
  className,
  height,
  onMouseEnter,
  onMouseLeave,
  src,
  width,
}) => (
  <img
    alt={alt}
    className={classNames(`ImageProxy`, className)}
    height={height}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    src={src}
    width={width}
  />
);

ImageProxy.propTypes = {
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default ImageProxy;
