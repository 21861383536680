import React from 'react';

import './Footnotes.css';

const Footnotes = (props) => (
  <div className="Footnotes">
    <hr />
    <dl>
      {props.children.map((child) => (
        <>
          <dt id={child.props.id}>{child.props.id.replace(/^fn-/, ``)}</dt>
          <dd>{child.props.children}</dd>
        </>
      ))}
    </dl>
  </div>
);

export default Footnotes;
