import React from 'react';
import PropTypes from 'prop-types';
import { Link, Meta, Title, Base } from 'react-head';
import { useStaticQuery, graphql, withAssetPrefix } from 'gatsby';

const SEO = ({ canonical, description, location, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata?.description;

  const defaultTitle = site.siteMetadata?.title;

  const url =
    canonical ||
    `${site.siteMetadata?.siteUrl || ``}${location?.pathname || ``}`;

  return (
    <>
      <Title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</Title>

      <Meta content={metaDescription} name="description" />

      <Meta content={title} name="og:title" />
      <Meta content={url} name="og:url" />
      <Meta content="website" name="og:type" />
      <Meta content="en" name="og:locale" />
      <Meta content="Sequencing.com" name="og:site_name" />
      <Meta content={metaDescription} name="og:description" />

      <Meta content="summary_large_image" name="twitter:card" />
      <Meta content={site.siteMetadata?.author || ``} name="twitter:site" />
      <Meta content={site.siteMetadata?.author || ``} name="twitter:creator" />
      <Meta content={title || defaultTitle} name="twitter:title" />
      <Meta content={site.siteMetadata?.author} name="twitter:domain" />
      <Meta content={metaDescription} name="twitter:description" />
      <Meta content={url} name="twitter:url" />
      <Base>
        <script src={withAssetPrefix("/isbot.js")}></script>
        <script>
          {`typeof document !== 'undefined' && !window.isbot?.default(window.navigator.userAgent) && !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");i.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="UCqS9Gt1jwLcEc4w9Z7ZcBeeaEjKz2tA";;analytics.SNIPPET_VERSION="5.2.0";
					analytics.load("${process.env.GATSBY_SEGMENT_KEY}");
					analytics.page();
				}}();`}
        </script>
      </Base>

      {!!url && !!url.length && <Link href={url} rel="canonical" />}
    </>
  );
};

SEO.defaultProps = {
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
