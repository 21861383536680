import React from 'react';

import { ReactComponent as ImageArrowToTop } from '@fortawesome/fontawesome-pro/svgs/solid/arrow-to-top.svg';

import Button from '../../01-atoms/Button/Button';
import TestProvidersList from '../../03-organisms/TestProvidersList/TestProvidersList';

import './DataUploadSection.css';

const DataUploadSection = () => (
  <div className="DataUploadSection">
    <h4>Select your test provider</h4>
    <TestProvidersList />
    <h4>Upload file</h4>
    <div>
      <Button cta href="/data/upload" nonReact>
        <ImageArrowToTop /> Upload from my computer
      </Button>
    </div>
  </div>
);

export default DataUploadSection;
