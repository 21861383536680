import React, { useMemo } from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player/lazy';

import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import ContentButton from '../../01-atoms/ContentButton/ContentButton';
import ContentMap from '../../01-atoms/ContentMap/ContentMap';
import DataUploadSection from '../../04-templates/DataUploadSection/DataUploadSection';
import Footnotes from '../../01-atoms/Footnotes/Footnotes';
import SimpleCtaSection from '../../04-templates/SimpleCtaSection/SimpleCtaSection';
import TableOfContents from '../../01-atoms/TableOfContents/TableOfContents';

import './PostBody.css';

const tableStyles = {
  overflow: `auto`,
};

const Table = (props) => (
  <div
    className="tableWrapper"
    style={
      props?.children[0]?.props?.children?.props?.children.length > 4
        ? {
            ...tableStyles,
            '--offset': `clamp(0vw, calc(((100vw - var(--max-width)) / 2) - var(--spacing__gutter) * 2), 100vw)`,
          }
        : tableStyles
    }
  >
    <table {...props} />
  </div>
);

const FootnoteChild = ({ children }) => {
  const child = children;
  if (child?.props?.className === `footnotes`) {
    return <Footnotes>{child.props.children[1].props.children}</Footnotes>;
  }
  return child;
};

const Wrapper = ({ children, ...props }) => {
  if (!Array.isArray(children)) {
    return <FootnoteChild>{children}</FootnoteChild>;
  }

  return children.map((child) => <FootnoteChild>{child}</FootnoteChild>);
};

const Player = (props) => (
  <div className="Player">
    <ReactPlayer
      {...props}
      className="ReactPlayer"
      height="100%"
      light
      width="100%"
    />
  </div>
);

const componentsMap = {
  ContentMap: ContentButton,
  CtaButton: ContentButton,
  DataUploadSection,
  SimpleCtaSection,
  TableOfContents: TableOfContents,
  table: Table,
  VideoPlayer: Player,
  wrapper: Wrapper,
};

const PostBody = ({
  children,
  className,
  contentMap,
  embeddedImagesLocal,
  embeddedImagesRemote,
  tableOfContents,
}) => {
  useMemo(() => {
    componentsMap.TableOfContents = (props) => (
      <TableOfContents {...props} tableOfContents={tableOfContents} />
    );
  }, [tableOfContents]);

  useMemo(() => {
    componentsMap.ContentMap = (props) => (
      <ContentMap {...props} contentMap={contentMap} />
    );
  }, [contentMap]);

  return (
    <div className={classnames(`PostBody`, className)}>
      <MDXProvider components={componentsMap}>
        <MDXRenderer
          localImages={embeddedImagesLocal}
          remoteImages={embeddedImagesRemote}
        >
          {children}
        </MDXRenderer>
      </MDXProvider>
    </div>
  );
};

export default PostBody;
